import { combineReducers } from 'redux';


import layoutReducer from "./Layout/reducer";


//login
import loginReducer from "./Auth/Login/reducer";

// File manager
import fileManagerReducer from "./FileManager/reducer";


const rootReducer = combineReducers({
    Layout: layoutReducer,
    login: loginReducer,
    fileManager: fileManagerReducer
})

export default rootReducer
import React, { useState } from "react"
import { Link } from "react-router-dom"
import { Card, CardBody, Col, Row, Container, Alert, Button, Spinner, Modal } from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { useSelector } from "react-redux";
import { RootState } from "src";
import { getFileIcon } from "src/util/files";
import { useAppDispatch } from "src/hooks/useAppDispatch";
import { deleteFile } from "src/slices/FileManager/thunks";
import { FileActionState } from "src/util/types";
import { ContentFile } from "./ContentFile";
import { EditFileForm } from "./EditFile";
import { doneEditingFile, isDeletingSelector, isUpdatingSelector } from "src/slices/FileManager/reducer";
import { downloadFile } from "src/api/api";


const OpenFile = () => {
  const { 
    selectedFile, 
    deleteStatus, 
    deleteMsg
  } = useSelector((state: RootState) => state.fileManager);
  const isDeleting = useSelector(isDeletingSelector)
  const isUpdating = useSelector(isUpdatingSelector)
  const dispatch = useAppDispatch()
  const [modal_large, setmodal_large] = useState(false);
  const [isDownloading, setisDownloading] = useState(false);

  const deleteSelectedFile =  (id: string) => {
    if(id){
      dispatch(deleteFile(id))
    } 
  }

  //Levantar modal de edición
  const tog_large = () => {
    if(!isUpdating) dispatch(doneEditingFile())
    setmodal_large(!modal_large);
    removeBodyCss();
  }
  const removeBodyCss = () => {
    document.body.classList.add("no_padding");
  }

  const descargarDocumento = async () => {
    setisDownloading(true);
    const url = await downloadFile(selectedFile.id)
    setisDownloading(false);
    window.location.href = url;
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col >
            {
              (!selectedFile) ?
                <Alert color="danger" className="mt-4">
                  No se ha seleccionado un documento. Intente nuevamente.
                </Alert>
              :
                <Card>
                  <h4 className="card-header">{selectedFile.nombre}</h4>
                  <CardBody>
                    {
                      (deleteStatus !== FileActionState.SUCCESS) && 
                      <ContentFile {...selectedFile}></ContentFile>
                    }
                    {
                      (deleteStatus === FileActionState.SUCCESS) &&
                      <Alert color="success" className="mt-2">
                        { deleteMsg }
                      </Alert>
                    }
                    {
                      (deleteStatus === FileActionState.ERROR) &&
                      <Alert color="danger" className="mt-2">
                        { deleteMsg }
                      </Alert>
                    }
                    {
                      (isDeleting) &&
                        <Spinner
                          className="spinner-border text-danger m-1 m-auto"
                          role="status"
                        ></Spinner>
                    }
                    <div className="d-flex flex-row-reverse">
                      <Link to="/repositorio/general" className="btn btn-primary m-2">
                        Volver
                      </Link>
                      <Button hidden={isDeleting || deleteStatus === FileActionState.SUCCESS} color="danger" className="m-2" onClick={ () => deleteSelectedFile(selectedFile.id)  }>
                      <i className="fas fa-trash-alt"></i> Eliminar
                      </Button>
                      <Button 
                        hidden={isDeleting || deleteStatus === FileActionState.SUCCESS} 
                        color="warning" 
                        className="m-2" 
                        data-toggle="modal"
                        onClick={() => {
                          tog_large();
                        }}
                        data-target=".bs-example-modal-lg">
                      <i className="fas fa-edit"></i> Editar 
                      </Button>
                      <Button 
                        hidden={isDeleting || deleteStatus === FileActionState.SUCCESS} 
                        color="success" 
                        className="m-2"
                        onClick={()=> {
                          descargarDocumento();
                          }}
                        >
                          {
                            (isDownloading) ? 
                            <Spinner className="spinner-border text-light m-1 m-auto" size="sm" role="status"></Spinner>
                            :
                            <>
                              <i className="fas fa-file-download"></i> Descargar
                            </>
                          }
                          
                      </Button>
                    </div>
                    <div className="d-flex flex-row-reverse">
                    </div>
                  </CardBody>
                </Card>
            }
            </Col>
          </Row>
        </Container>
      </div>
      <Modal
        size="lg"
        isOpen={modal_large}
        toggle={() => {
          tog_large();
        }}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myLargeModalLabel">
            Editar documento
          </h5>
          <button
            onClick={() => {
              setmodal_large(false);
            }}
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <EditFileForm {...selectedFile}></EditFileForm>
        </div>
      </Modal>
    </React.Fragment >

  )

}
export default OpenFile
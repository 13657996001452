interface MenuItemsProps {
    id: number;
    novidade ?: any;
    label: string;
    icon ?: string;
    link ?: string;
    badge?:string;
    badgecolor?:string;
    subItems ?: any;
    isHeader ?: boolean
}

const menuItems: Array<MenuItemsProps> = [
    {
        id: 1,
        label: "Dashboards",
        isHeader : true
    },
    {
        id: 2,
        label: "Documentos",
        icon: "folder",
        link: "/repositorio/general",
    }
];

export { menuItems };

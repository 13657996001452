import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "src";
import { Archivo, FileActionState  } from "src/util/types";

const emptyFile: Archivo = {
  descripcion: '',
  fecha_incorporacion: '',
  id: '',
  mime_type: '',
  nombre: '',
  nombre_documento: '',
  tipo: '',
  fecha_emision: ''
}

export const fileManagerSlice = createSlice({
  name: "fileManager",
  initialState: {
    files: []  as Archivo[],
    filesStatus: FileActionState.NOT_STARTED,
    filesMsg: '',
    originalFiles: [] as Archivo[],
    selectedFile: {} as Archivo,
    deleteStatus: FileActionState.NOT_STARTED,
    deleteMsg: '',
    updateStatus: FileActionState.NOT_STARTED,
    updateMsg: '',
    uploadStatus: FileActionState.NOT_STARTED,
    uploadMsg: ''
  },
  reducers: {
    startLoadingFiles: (state) => {
      state.filesStatus = FileActionState.LOADING;
      state.selectedFile = emptyFile;
      state.deleteStatus = FileActionState.NOT_STARTED;
      state.deleteMsg = '';
      state.originalFiles = [];
      state.files = [];
    },
    setFiles: (state, action) => {
      state.filesStatus = FileActionState.SUCCESS;
      state.files = action.payload.files;
      state.originalFiles = action.payload.files;
      state.filesMsg = ''
    },
    errorGettingFiles: (state, action) => {
      state.filesStatus = FileActionState.ERROR;
      state.filesMsg = `Error al traer los documentos. Error: ${action.payload.message}. Intente nuevamente más tarde.`
    },
    setFilterFiles: (state, action) => {
      state.files = state.originalFiles.filter( doc => doc.nombre.toLowerCase().includes(action.payload.filtro))
    },
    setOriginalFiles: (state) =>{
      state.files = state.originalFiles;
    },
    setSelectedFile: (state, action) => {
      const fileSelected = state.files.find( file => file.id === action.payload.id)
      state.selectedFile = (fileSelected) ?? emptyFile;
      state.deleteStatus = FileActionState.NOT_STARTED;
      state.deleteMsg = '';
    },
    startEliminatingFile: (state) => {
      state.deleteStatus = FileActionState.LOADING;
    },
    errorEliminatingFile: (state) => {
      state.deleteStatus = FileActionState.ERROR;
      state.deleteMsg = `No se pudo eliminar el documento.`;
    },
    doneEliminatingFile: (state) => {
      state.deleteStatus = FileActionState.SUCCESS;
      state.deleteMsg = 'Archivo eliminado.'
    },
    startEditingFile: (state) => {
      state.updateStatus = FileActionState.LOADING;
    },
    successEditingFile: (state) => {
      state.updateStatus = FileActionState.SUCCESS;
      state.updateMsg = "Cambios guardados con éxito." 
    },
    errorEditingFile: (state) => {
      state.updateStatus = FileActionState.ERROR;
      state.deleteMsg = "Error al guardar los cambios";
    },
    doneEditingFile: (state) => {
      state.updateMsg = '';
      state.updateStatus = FileActionState.NOT_STARTED;
    },
    startUploadingFile: (state) => {
      state.uploadStatus = FileActionState.LOADING;
    },
    successUploadingFile: (state) => {
      state.uploadStatus = FileActionState.SUCCESS;
      state.uploadMsg = 'Documento subido exitosamente.';
    },
    errorUploadingFile: (state) => {
      state.uploadStatus = FileActionState.ERROR;
      state.uploadMsg = 'Error al cargar el documento.';
    },
    doneUploadingFile: (state) => {
      state.uploadStatus = FileActionState.NOT_STARTED;
      state.uploadMsg = '';
    }
  }
});

export const isDeletingSelector = (state: RootState) : boolean => {
  return (state.fileManager.deleteStatus === FileActionState.LOADING);
}
export const isUpdatingSelector = (state: RootState) : boolean => {
  return (state.fileManager.updateStatus === FileActionState.LOADING);
}
export const isLoadingFilesSelector = (state: RootState) : boolean => {
  return (state.fileManager.filesStatus === FileActionState.LOADING);
}


export const {
  startLoadingFiles,
  setFiles,
  setFilterFiles,
  setOriginalFiles,
  setSelectedFile,
  startEliminatingFile,
  errorEliminatingFile,
  doneEliminatingFile,
  startEditingFile,
  successEditingFile,
  errorEditingFile,
  doneEditingFile,
  startUploadingFile,
  successUploadingFile,
  errorUploadingFile,
  doneUploadingFile,
  errorGettingFiles
} = fileManagerSlice.actions

export default fileManagerSlice.reducer;

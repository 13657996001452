import React, { useState, useEffect } from "react";
import { Container, Row, Col, Card, Alert, CardBody } from "reactstrap";

//redux
import { useSelector, useDispatch } from "react-redux";

// withRouter
import withRouter from "src/components/Common/withRouter";

//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb";

import userIcon from "../../assets/images/users/user-icon.png";
// actions
import { createSelector } from "reselect";


const UserProfile = () => {
  document.title = "Perfil de usuario | Libro de Contratos Digital";
  const dispatch = useDispatch();

  const selectLayoutProperties = createSelector(
    (state: any) => state.profile,
    (layout) => ({
      error: layout.error,
      success: layout.success,
    })
  );
  // Inside your component
  const {
    error, success
  } = useSelector(selectLayoutProperties);

  const [email, setemail] = useState<string>("");
  const [name, setname] = useState<string>("");
  const [idx, setidx] = useState<number>(1);

  useEffect(() => {
    const authUser: any = localStorage.getItem("authUser");

    if (authUser) {
      const obj = JSON.parse(authUser);
      
      if (obj.name) {
        setemail(obj.email);
        setname(obj.name);
        setidx(obj.id);
      }
      
    }

  }, [dispatch, success]);


  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumb title="Libro de Contratos Digital" breadcrumbItem="Perfil" />

          <Row>
            <Col lg="12">
              {error && error ? <Alert color="danger">{error}</Alert> : null}
              {success ? <Alert color="success">{success}</Alert> : null}

              <Card>
                <CardBody>
                  <div className="d-flex">
                    <div className="ms-3">
                      <img
                        src={userIcon}
                        alt=""
                        className="avatar-md rounded-circle img-thumbnail"
                      />
                    </div>
                    <div className="flex-grow-1 align-self-center ms-3">
                      <div className="text-muted">
                        <h5>{name}</h5>
                        <p className="mb-1">{email}</p>
                        <p className="mb-0">Id no: #{idx}</p>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(UserProfile);
import React from 'react'
import { isoToShortDate } from 'src/util/date'
import { getFileIcon } from 'src/util/files'
import { Archivo } from 'src/util/types'

export const ContentFile: React.FC<Archivo> = ({ descripcion, fecha_emision, fecha_incorporacion, tipo, nombre_documento, mime_type }) => {
    return (
        <React.Fragment>
            <div className="fw-bold">
                Descripción
            </div>
            <p>{descripcion}</p>
            <div className="fw-bold">Fecha de emisión</div>
            <p>{(fecha_emision) ? isoToShortDate(fecha_emision) : 'No tiene'}</p>
            <div className="fw-bold">Fecha de incorporación</div>
            <p>{isoToShortDate(fecha_incorporacion)}
            </p>
            <div className="fw-bold">Tipo de documento</div>
            <p>{tipo}</p>
            <div className="fw-bold">Nombre del documento</div>
            <p><i className={ getFileIcon(mime_type) }></i> {nombre_documento}</p>
        </React.Fragment >
    )
}

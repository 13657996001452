// action
import { apiError } from "./reducer";

import { login } from "src/api/api";
import { User } from "src/util/types";

export const loginUser: any =
  ({ email, password }: { email: string; password: string }, history: any) =>
  async (dispatch: any) => {
    try {
      const data: User = await login(email, password);

      if (data) {
        history("/repositorio/general");
      }
    } catch (error) {
      dispatch(apiError(error));
    }
  };

export const logoutUser: any = (history: any) => async (dispatch: any) => {
  try {
    localStorage.removeItem("authUser");
    history("/login");
  } catch (error) {
    dispatch(apiError(error));
  }
};

import React from 'react'

import { Link } from 'react-router-dom'
import { Table } from 'reactstrap'

import { Archivo } from 'src/util/types'
import { getFileIcon } from 'src/util/files'
import { isoToShortDate } from 'src/util/date'
import { setSelectedFile } from 'src/slices/FileManager/reducer'
import { useAppDispatch } from 'src/hooks/useAppDispatch'

export const FilesTable: React.FC<{ files: Archivo[] }> = ({files}) => {
  const dispatch = useAppDispatch()

  return (
    <div className="table-responsive">
      <Table className="table align-middle table-nowrap table-hover mb-0">
        <thead>
          <tr>
            <th scope="col">Nombre</th>
            <th scope="col">Fecha de emisión</th>
            <th scope="col">Fecha de incorporación</th>
            <th scope="col">Tipo</th>
          </tr>
        </thead>
        <tbody>
          {
            (files && files.length > 0) ?
            files.map((documento) => (
              <tr key={documento.id}>
                <td>
                  <Link to="/repositorio/ver-documento" className="text-dark fw-medium" onClick={() => dispatch( setSelectedFile( {id: documento.id}) ) }>
                    <i className={ getFileIcon(documento.mime_type) }></i> {documento.nombre}
                  </Link>
                </td>
                <td>{(documento.fecha_emision) ? isoToShortDate(documento.fecha_emision) : 'No tiene'}</td>
                <td>{isoToShortDate(documento.fecha_incorporacion)}</td>
                <td>{documento.tipo}</td>
              </tr>
            ))
            :
            <p>No hay archivos.</p>
          }
        </tbody>
      </Table>
    </div>
  )
}

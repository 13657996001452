import { Navigate } from "react-router-dom";

//profile
import UserProfile from "../pages/Authentication/user-profile"

//Authentication pages
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import FileManager from "../pages/FileManager";
import NewFile from "../pages/FileManager/NewFile";
import OpenFile from "src/pages/FileManager/OpenFile";
import Comunications from "src/pages/Comunications";

interface RouteProps {
  path: string;
  component: any;
  exact?: boolean;
}

const userRoutes: Array<RouteProps> = [
  // //profile
  { path: "/profile", component: <UserProfile/> },

  // Gestor de documentos
  { path: "/repositorio/general", component: <FileManager/>},
  { path: "/repositorio/subir-documento", component: <NewFile/>},
  { path: "/repositorio/ver-documento", component: <OpenFile/>},
  
  // Administrador de comunicaciones
  { path: "/comunicaciones/general", component: <Comunications/>},

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  // { path: "/", exact: true, component: () => <Navigate to="/sales" /> }
  {
    path: "/",
    exact: true,
    component: <Navigate to="/repositorio/general" />,
  },
  { path: "*", component: <Navigate to="/repositorio/general" /> },
];

const authRoutes: Array<RouteProps> = [
  //Authentication pages
  { path: "/login", component: <Login/> },
  { path: "/logout", component: <Logout/> }

];

export { userRoutes, authRoutes };

import React from 'react'
import { Alert, Button, Col, Form, FormFeedback, Input, Label, Row, Spinner } from 'reactstrap';
import { useSelector } from 'react-redux';
import { useFormik } from "formik";
import * as Yup from "yup";
import { Archivo, EditarArchivo, FileActionState } from "src/util/types";
import { useAppDispatch } from 'src/hooks/useAppDispatch';
import { RootState } from 'src';
import { updateFile } from 'src/slices/FileManager/thunks';
import { isUpdatingSelector } from 'src/slices/FileManager/reducer';

export const EditFileForm: React.FC<Archivo> = ({id, nombre, fecha_emision, descripcion, tipo}) => {

  const dispatch = useAppDispatch();
  const { updateMsg, updateStatus } = useSelector((state: RootState) => state.fileManager)
  const isUpdating = useSelector(isUpdatingSelector);

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      nombre,
      descripcion,
      fecha_emision,
      tipo,
    },
    validationSchema: Yup.object({
      nombre: Yup.string().required("Por favor ingresa el nombre del documento."),
      descripcion: Yup.string().required("Por favor ingresa una descripción."),
      fecha_emision: Yup.string(),
      tipo: Yup.string().required("Por favor ingresa el tipo de documento.."),
    }),
    onSubmit: (values) => {

      const data: EditarArchivo = {
        id,
        nombre: values.nombre,
        descripcion: values.descripcion,
        fecha_emision: values.fecha_emision,
        tipo: values.tipo
      }
      dispatch(updateFile(data))
    },
  });

  return (
    <>
    <Form
      className="needs-validation"
      onSubmit={e => {
        e.preventDefault();
        validation.handleSubmit();
        return false;
      }}>
      <Row className="my-4"> {/* Nombre */}
        <Label htmlFor="file-name" className="col-sm-3 col-form-label">Nombre</Label>
        <Col sm={9}>
          <Input
            name="nombre"
            placeholder="Nombre del documento"
            type="text"
            className="form-control"
            id="file-name"
            disabled = {isUpdating}
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            value={validation.values.nombre || ""}
            invalid={ !!(validation.touched.nombre && validation.errors.nombre) }
          />
          { 
            validation.touched.nombre &&
            validation.errors.nombre && 
            (<FormFeedback type="invalid">
              {validation.errors.nombre}
            </FormFeedback>)
          }
        </Col>
      </Row>
      <Row className="mb-4"> {/* Descripción */}
        <Label htmlFor="file-description" className="col-sm-3 col-form-label">Descripción</Label>
        <Col sm={9}>
          <Input
            name="descripcion"
            placeholder="Descripción del documento"
            type="textarea"
            className="form-control "
            id="file-description"
            disabled = {isUpdating}
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            value={validation.values.descripcion || ""}
            invalid={ !!(validation.touched.descripcion && validation.errors.descripcion) }
          />
          {validation.touched.descripcion &&
            validation.errors.descripcion ? (
            <FormFeedback type="invalid">
              {validation.errors.descripcion}
            </FormFeedback>
          ) : null}
        </Col>
      </Row>
      <Row className="my-4"> {/* Tipo */}
        <Label htmlFor="file-type" className="col-sm-3 col-form-label">Tipo</Label>
        <Col sm={9}>
          <Input
            name="tipo"
            placeholder="Tipo de documento"
            type="text"
            className="form-control"
            id="file-type"
            disabled = {isUpdating}
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            value={validation.values.tipo || ""}
            invalid={ !!(validation.touched.tipo && validation.errors.tipo) }
          />
          {validation.touched.tipo &&
            validation.errors.tipo ? (
            <FormFeedback type="invalid">
              {validation.errors.tipo}
            </FormFeedback>
          ) : null}
        </Col>
      </Row>
      <Row className="my-4"> {/* Fecha de emisión */}
        <Label htmlFor="fecha_emision" className="col-sm-3 col-form-label">Fecha de emisión</Label>
        <Col sm={9}>
          <Input
            name="fecha_emision"
            placeholder="Tipo de documento"
            type="date"
            className="form-control"
            id="fecha_emision"
            disabled = {isUpdating}
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            value={validation.values.fecha_emision || ""}
            invalid={ !!(validation.touched.fecha_emision && validation.errors.fecha_emision) }
          />

        </Col>
      </Row>

      <Row>
      </Row>
      <Button disabled={isUpdating} color="primary" type="submit">
        Guardar cambios
      </Button>
    </Form>
    {
      (isUpdating) ?
        <Spinner
          className="spinner-border text-primary mt-4"
          role="status"
        ></Spinner>
        : <></>
    }
    {
      (updateStatus === FileActionState.SUCCESS || updateStatus === FileActionState.ERROR) &&
        <Alert color={updateStatus === FileActionState.SUCCESS ? 'success': 'danger'} className="mt-4">
          {updateMsg}
        </Alert>
    }
    </>
  )
}
